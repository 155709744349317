import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseComponent } from './course.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { AuthGuard } from 'app/auth/helpers';
import { RouterModule } from '@angular/router';
import { CourseService } from './course.service';
import { PlyrModule } from 'ngx-plyr';
import { VideoPlayerComponent } from './video-player/video-player.component';
import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false
};

const routes = [
  {
    path: '',
    component: CourseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'watch/:courseId',
    component: CourseDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'watch/:courseId/:videoId',
    component: CourseDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
];

@NgModule({
  providers: [
    CourseService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    NgbModule,
    PlyrModule,
    PerfectScrollbarModule
  ],
  declarations: [CourseComponent, CourseDetailComponent, VideoPlayerComponent]
})
export class CourseModule { }
