import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CourseService } from './../course.service';
import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Output, ViewEncapsulation, HostListener } from '@angular/core';
import Plyr from 'plyr';
// import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverUpdateSourceParams, PlyrDriverDestroyParams } from 'ngx-plyr/lib/plyr-driver/plyr-driver';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input('videoLink') videoLink;
  @Input('currentVideo') currentVideo;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this._courseService.updateVideoStatus({ status: 1, time: this.player.currentTime });
  }

  public player: Plyr;
  public plyrOptions = { tooltips: { controls: true }, displayDuration: true };
  public videoSources: Plyr.Source[] = [];
  public localPlaybackTime = { metaData: [] };
  public hasSetCurrentTime = false;
  public videoStatus = 0;

  public time = 0;
  videoStatusSub: any = { status: 0, time: 0 };
  private _unsubscribeAll = new Subject<any>();

  constructor(private _courseService: CourseService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.extractVideoId();
  }

  ngOnInit() {
    // this.localPlaybackTime = JSON.parse(localStorage.getItem('momoclass-playback'));
    this.videoStatusSub = this._courseService.getVideoStatusListener().pipe(takeUntil(this._unsubscribeAll))
      .subscribe((videoStatus: any) => {
        console.log('setLocalPlayback videoStatus', videoStatus)
        this.videoStatusSub = videoStatus;
      });
  }

  onInit($event) {
    this.player = $event;
    // const index = this.localPlaybackTime.metaData.findIndex(l => l._id === this.currentVideo._id);
    // if (index >= 0) {
    //   this.player.currentTime = this.localPlaybackTime.metaData[index].backupTime;
    // }
  }

  extractVideoId() {
    this.localPlaybackTime = JSON.parse(localStorage.getItem('momoclass-playback'));
    if (!this.localPlaybackTime) {
      localStorage.setItem(`momoclass-playback`, JSON.stringify({ metaData: [] }));
      this.localPlaybackTime = JSON.parse(localStorage.getItem('momoclass-playback'));
    }

    this.videoSources = [];
    const matches = this.videoLink.match(/(?:youtu\.be\/|youtube\.com\/embed\/)([A-Za-z0-9_-]+)/);
    if (matches) {
      let videoId = matches[1];
      this.videoSources.push({ src: videoId, provider: 'youtube', });
    } else {
      this.videoSources.push({ src: this.videoLink, type: 'video/mp4', size: 1080, });
    }

  }

  onCanPlay(event: Plyr.PlyrEvent) {
    console.log('onCanPlay', event);
  }

  onPlayed(event: Plyr.PlyrEvent) {
    this._courseService.updateVideoStatus({ status: 1, time: this.player.currentTime });
    // console.log('played', event);
  }

  onPlaying(event: Plyr.PlyrEvent) {
    // console.log('onPlaying', event);
    const index = this.localPlaybackTime.metaData.findIndex(l => l._id === this.currentVideo._id);
    console.log(this.player.duration);
    if (index >= 0 && !this.hasSetCurrentTime) {
      if (this.localPlaybackTime.metaData[index].backupTime < this.player.duration) {
        this.player.currentTime = this.localPlaybackTime.metaData[index].backupTime;
      } else {
        // this.player.currentTime = 0;
      }
      this.hasSetCurrentTime = true;
    }

    if (!this.hasSetCurrentTime) {
      if (this.currentVideo.minutes < this.player.duration) {
        this.player.currentTime = this.currentVideo.minutes * 60;
      } else {
        // this.player.currentTime = 0;
      }
      this.hasSetCurrentTime = true;
    }
  }

  onPause(event: Plyr.PlyrEvent) {
    this._courseService.updateVideoStatus({ status: 1, time: this.player.currentTime });
  }

  onProgress(event: Plyr.PlyrEvent) {
    // console.log('onProgress', event.detail.plyr.currentTime);
  }

  onLoadedMetadata(event: Plyr.PlyrEvent) {

  }

  onEnded(event: Plyr.PlyrEvent) {
    this._courseService.updateVideoStatus({ status: 2, time: this.player.currentTime });
    // this.videoStatus = 2;
  }

  onStageChange(event) {
    // code -1: Unstarted, 0: Ended, 1: Playing, 2: Paused, 3: Buffering, 5: Video cued. 
    // console.log('onStageChange', event.detail.code);
  }

  ngOnDestroy(): void {
    // console.log('ngOnDestroy currentTime', this.player.currentTime, this.currentVideo);
    const index = this.localPlaybackTime.metaData.findIndex(l => l._id === this.currentVideo._id);
    console.log('this.videoStatusSub', this.videoStatusSub);
    console.log('index', index);

    if (index >= 0 && this.videoStatusSub.status != 0) {
      if (this.videoStatusSub.status == 2) {
        this.localPlaybackTime.metaData[index].backupTime = 0;
      } else {
        this.localPlaybackTime.metaData[index].backupTime = this.videoStatusSub.time;
      }
    } else {
      let playbackBody;
      if (this.videoStatusSub.status == 2) {
        playbackBody = {
          ...this.currentVideo,
          backupTime: 0
        }
      } else {
        playbackBody = {
          ...this.currentVideo,
          backupTime: this.videoStatusSub.time
        }
      }
      this.localPlaybackTime.metaData.push(playbackBody);
    }
    console.log('ngOnDestroy localPlaybackTime', this.localPlaybackTime);
    localStorage.setItem(`momoclass-playback`, JSON.stringify(this.localPlaybackTime));

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


}
