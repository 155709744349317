// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://api.momocargo.co:5000/api',
  // apiUrl: 'https://192.168.1.199:4433/api',
  ipUrl: 'https://api.ipify.org/?format=json',
  aws: {
    accessKeyId: 'AKIASYD7CIKFR4FXQ4D2',
    secretAccessKey: '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',
    bucketName: 'momocargo',
    region: 'ap-southeast-1',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
