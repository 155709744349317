<div class="content-wrapper p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <div class="row m-content">
      <div class="col-12 px-0">
        <div class="card no-box-shadow mb-0">
          <div class="card-header">
            <h1 class="c-gray mb-0">ประวัติเวอร์ชั่นเว็บไซต์</h1>
          </div>
          <div class="card-body collapse-icon">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
              <ngb-panel *ngFor="let item of changeList">
                <ng-template ngbPanelTitle>
                  <div class="d-flex justify-content-between align-items-center mr-5">
                    <span class="lead collapse-title fs-3">V{{ item.version }}</span>
                    <span class="fs-5">วันที่อัพเดท {{ item.updated_date }}</span>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <span class="fs-4">{{ item.description }}</span>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

          </div>
        </div>
      </div>
    </div>
  </div>