import { AuthGuard } from 'app/auth/helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { VersionControlComponent } from './version-control.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

//routing
const routes: Routes = [
  {
    path: '',
    component: VersionControlComponent,
    canActivate: [AuthGuard]
  },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    NgbModule
  ],
  declarations: [VersionControlComponent]
})
export class VersionControlModule { }
