import { CourseService } from './../course/course.service';
import { DashboardService } from './dashboard.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { DashboardComponent } from './dashboard.component';

import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { AuthGuard } from 'app/auth/helpers'
import { NgApexchartsModule } from 'ng-apexcharts';
import { CommonModule } from '@angular/common';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false
};

const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'exit-learning',
  //   canActivate: [RedirectGuard],
  //   data: {
  //     externalUrl: 'https://s1.momocargo.com/dashboard'
  //   }
  // },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
];

@NgModule({
  providers: [
    StripHtmlPipe,
    DashboardService,
    CourseService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
    NgApexchartsModule,
    PerfectScrollbarModule
  ],
  exports: [DashboardComponent]
})
export class DashboardModule { }
