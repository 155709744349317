import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  private videoStatus = new Subject<any>();
  private videoPlaybackTime = new Subject<any>();
  private currentVideoSub = new Subject<any>();
  private currentVideoList = new Subject<any>();

  currentVideo;

  constructor(private _httpClient: HttpClient) {
  }

  getCategoryList(page: number, limit: number, type: string): Observable<any> {
    return this._httpClient.get<any>(`/video-category/get/${page}/${limit}/${type}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  getCategory(id: string): Observable<any> {
    return this._httpClient.get<any>(`/video-category/get/info/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }


  getVideoList(page: number, limit: number, type: string) {
    return this._httpClient.get<any>(`/video/get/${page}/${limit}/${type}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    ).subscribe(videoList => {
      this.currentVideoList.next(videoList.data);
    });
  }

  getVideo(id: string) {
    return this._httpClient.get<any>(`/video/get/info/${id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    ).subscribe(video => {
      this.currentVideoSub.next(video.data);
      this.currentVideo = video.data;
    });
  }

  geSeenVideo(video_id: string, user_id: string): Observable<any> {
    return this._httpClient.get<any>(`/seenvideo/get/${video_id}/${user_id}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  updateSeenVideo(id: number): Observable<any> {
    return this._httpClient.patch<any>(`/seenvideo/update`, { video_id: id }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    return throwError('เกิดเหตุขัดข้อง กรุณาลองใหม่อีกครั้งในภายหลัง');
    // return throwError('Something went wrong, please try again later.');
  }

  httpOptions(body: any) {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token')),
      body: body
    }
  }

  updateVideoStatus(status: any) {
    this.videoStatus.next(status);
  }

  getVideoStatusListener() {
    return this.videoStatus.asObservable();
  }

  getCurrentVideo() {
    return this.currentVideoSub.asObservable();
  }

  getCurrentVideoList() {
    return this.currentVideoList.asObservable();
  }

  updateVideoPlaybackTime(data: any) {
    this.videoPlaybackTime.next(data);
  }

  getVideoPlaybackTimeListener() {
    return this.videoPlaybackTime.asObservable();
  }

}
