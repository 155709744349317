<div class="content-wrapper p-0">
  <div class="content-body mb-5">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <!-- Row 1 -->
    <div class="row mx-2 mt-2 mb-0 match-height justify-content-center momo-container">
      <!-- Left Card -->
      <div class="col-xl-7 col-12 pr-xl-50">
        <div class="card no-box-shadow mb-1">
          <div class="row">
            <div class="col-12 col-lg-8 pr-md-0 border-r">
              <div class="card-header aligns-items-center">
                <h2 class="c-gray mb-0 font-weight-bolder">การเข้าเรียน</h2>
              </div>
              <div class="card-body">
                <div id="learningHours">
                  <apx-chart [series]="learningHoursOptions.series" [chart]="learningHoursOptions.chart"
                    [dataLabels]="learningHoursOptions.dataLabels" [plotOptions]="learningHoursOptions.plotOptions"
                    [yaxis]="learningHoursOptions.yaxis" [xaxis]="learningHoursOptions.xaxis"
                    [legend]="learningHoursOptions.legend" [colors]="learningHoursOptions.colors"
                    [grid]="learningHoursOptions.grid"></apx-chart>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4  bor pl-md-0">
              <div class="card-body d-flex flex-column justify-content-center align-content-center text-center">
                <img src="assets/images/momo-class/groceries 1.png" alt="" class="w-100 mx-auto"
                  style="max-width: 7.5rem;">
                <h3 class="font-weight-bold mb-0">ใช้เวลาเรียนไปทั้งหมด</h3>
                <p class="font-weight-bolder text-danger my-1 fa-5x">{{ dashboardData?.learning_hour ?? '0' }}</p>
                <p class="text-muted">ชั่วโมง</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ Left Card -->

      <!-- Right Card -->
      <div class="col-xl-5 col-12 pl-xl-50">
        <div class="card no-box-shadow mb-1">
          <div class="card-body aligns-items-center">
            <h2 class="c-gray mb-0 font-weight-bolder mb-1">โปรไฟล์ของฉัน</h2>
            <div class="row match-height">
              <div class="col-6 pr-md-0 border-right">
                <div class="d-flex flex-column justify-content-center align-content-center text-center h-100">
                  <div class="avatar avatar-xxl mx-auto">
                    <!-- <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar" /> -->
                    <img *ngIf="currentUser?.avatar" class="round no-box-shadow" [src]="currentUser.avatar"
                      alt="avatar" />
                    <div *ngIf="!currentUser?.avatar"
                      class="no-avatar-user rounded-circle d-flex align-items-center justify-content-center "
                      style="width: 100px;height: 100px;">
                      <span class="fa-regular fa-user  text-primary fa-2x"></span>
                    </div>
                  </div>
                  <div class="my-1">
                    <h3 class="font-weight-bold mb-0">{{ currentUser?.name || currentUser?.phone }}</h3>
                    <h4 class="mb-0 text-muted">{{ currentUser?.email ?? '-' }}</h4>
                  </div>
                  <a class="d-inline-flex justify-content-center mt-auto"
                    href="https://customer.momocargo.co/profile/information">
                    <i class="fa-regular fa-pencil-alt fa-1x mr-50 text-primary "></i>
                    <h4 class="text-primary mb-0">แก้ไขโปรไฟล์</h4>
                  </a>
                </div>
              </div>
              <div class="col-6 mt-2 mt-lg-0">
                <div class="d-flex flex-column text-left">
                  <div class="">
                    <h4 class="text-muted mb-0">แพ็กเกจ:</h4>
                    <h4 class="font-weight-bold mb-0">{{ getPackageName() }}</h4>
                    <a href="https://customer.momocargo.co/momo-class"
                      *ngIf="getPackageName() != 'สมัครแพ็คเกจพรีเมี่ยม'">
                      <h4 class="text-primary mb-0">อัปเกรด</h4>
                    </a>
                  </div>
                  <div class="my-1">
                    <h4 class="text-muted mb-0 ">วันหมดอายุ:</h4>
                    <h4 class="font-weight-bold mb-0">ตลอดชีพ</h4>
                  </div>
                  <div class="">
                    <h4 class="text-muted mb-0">วันที่เริ่มสมัคร:</h4>
                    <h4 class="font-weight-bold mb-0">17 กุมภาพันธ์ 2567</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Card -->
    </div>
    <!-- Row 1 -->

    <div class="row mx-2 match-height justify-content-center d-none d-md-block momo-container">
      <!-- Ads Card -->
      <div class="col-12">
        <img src="assets/images/momo-class/course 1.png" alt="ads" class="w-100" />
      </div>
      <!-- Ads Card -->
    </div>

    <!-- Row 2 -->
    <div class="row mx-2 mt-1 mb-0 match-height justify-content-cente momo-container">
      <!-- Left Card -->
      <div class="col-xl-5 col-12 pr-xl-50" *ngIf="currentUser?.momoclass == 'advance'">
        <div class="card no-box-shadow mb-1">
          <div class="card-header aligns-items-center">
            <h2 class="c-gray mb-0 font-weight-bolder">แปลไทย-จีนของฉัน</h2>
            <a class="d-inline-flex paginate-link" routerLink="/translation">
              <p class="text-primary mr-50 mb-0 ">คำแปลทั้งหมด</p>
              <i class="fa-regular fa-chevron-right text-primary"></i>
            </a>
          </div>
          <div class="card-body" [ngClass]="{ 'row' : !translateLoading && translateData?.data.data.length == 0}">
            <ng-container *ngIf="!translateLoading && translateData?.data.data.length != 0">

              <perfect-scrollbar style=" max-height: 300px;">
                <div class="collapse-icon">
                  <ngb-accordion [closeOthers]="true" (shown)='panelExpanded($event, listTranslatePanel)'
                    (hidden)="panelCollapse($event, listTranslatePanel)" #listTranslatePanel>
                    <ngb-panel [disabled]="trans.answer == ''" *ngFor="let trans of translateData.data.data">
                      <ng-template ngbPanelTitle>
                        <span class="collapse-title">
                          <i class="fa-light fa-language fa-2x text-dark"></i>
                          <div class="collapse-content mr-1">
                            <h4 class="text-secondary mb-0 pr-1">
                              {{ trans.question | striphtml }}
                            </h4>
                            <p class="collapse-status mb-0">
                              <ng-container *ngIf="trans.answer == ''">
                                <i class="fa-solid fa-clock text-warning fa-xs"></i>
                                โปรดรอ
                              </ng-container>
                              <ng-container *ngIf="trans.answer != ''">
                                <i class="fa-solid fa-circle-check text-success fa-xs"></i>
                                แปลแล้ว
                              </ng-container>
                            </p>
                          </div>
                        </span>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <p>
                          {{ trans.answer | striphtml }}
                        </p>
                        <a class="paginate-link text-dark" (click)="copyCode(trans.answer)">
                          <i class="fa-regular fa-copy text-dark"></i>
                          คัดลอก
                        </a>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
                </div>
              </perfect-scrollbar>
              <div class="row align-self-end mt-2">
                <a class="d-inline-flex btn-paginate-link m-auto" routerLink="/translation">
                  <i class="fa-regular fa-plus-circle text-primary  mr-50"></i>
                  <p class="text-primary mb-0 ">สร้างคำถาม/ให้ล่ามจีนแปลภาษา</p>
                </a>
              </div>
            </ng-container>
            <ng-container *ngIf="!translateLoading && translateData?.data.data.length == 0">
              <div class="no-data">
                <i class="fa-regular fa-language fa-3x text-muted"></i>
                <div class="no-data-content">
                  <h1 class="font-weight-bold mb-0">
                    ยังไม่มีการแปลที่ถูกส่งโดยคุณ
                  </h1>
                  <small class="text-muted mb-50" [routerLink]="['/translate']">
                    คลิกที่นี่เพื่อเพิ่มคำแปลไทย-จีน
                  </small>
                  <a class="paginate-link">
                    <i class="fa-regular fa-plus-circle fa-2x text-primary"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="translateLoading">
              <div class="collapse-icon" style="overflow:hidden">
                <div *ngFor="let trans of [0,0,0,0,0,0]">
                  <span class="collapse-title">
                    <ngx-skeleton-loader
                      [theme]="{width: '50px','border-radius': '4px',height: '50px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <div class="collapse-content mr-1">
                      <ngx-skeleton-loader
                        [theme]="{width: '140px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                      <p class="collapse-status mb-0">
                        <ngx-skeleton-loader
                          [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!--/ Left Card -->

      <!-- Right Card -->
      <div class="col-xl-7 col-12 pl-xl-50"
        [ngClass]="{'col-xl-7' : currentUser?.momoclass == 'advance','col-xl-12' : currentUser?.momoclass != 'advance'}">
        <div class="card no-box-shadow mb-1">
          <div class="card-header aligns-items-center "
            [ngClass]="{ 'pb-1' : !translateLoading && dashboardData?.last_course.length != 0}">
            <h2 class="c-gray mb-0 font-weight-bolder">คอร์สเรียนล่าสุด</h2>
          </div>
          <div class="card-body">
            <ng-container *ngIf="!dashboardLoading && dashboardData?.last_course.length !=0">
              <perfect-scrollbar [config]="config">
                <div class="course-no-wrap">
                  <div class="course-card my-50" *ngFor="let course of dashboardData?.last_course;let i as index"
                    [routerLink]="['/course/watch',course._id]">
                    <img [src]="course.image" [alt]="course.name">
                    <div class="course-body">
                      <h4 class="font-weight-bold text-ellipsis-1 mb-0">
                        {{ course.name }}
                      </h4>
                      <p class="text-muted text-ellipsis-2 " [ngClass]="{'mb-0' : course.description != ''}">
                        {{ course.description }}
                      </p>
                      <div class="row mx-0 align-items-center border-bottom pb-50">
                        <div class="progress-wrapper col-10 px-0">
                          <ngb-progressbar type="gradient-primary" class="progress-bar-primary"
                            [value]="course.percent ?? 0">
                          </ngb-progressbar>
                        </div>
                        <small class="col px-0 text-right">{{ course.percent ?? 0 }}%</small>
                      </div>

                      <div class="d-flex w-100 justify-content-between mt-50">
                        <p class="text-muted mb-0">
                          <i class="fa-regular fa-clock"></i>
                          {{ course.minutes ?? '00:00' }} นาที
                        </p>
                        <div>
                          <span class="badge bg-light-primary">{{ course.learned ?? 0 }}/{{ course.total_video ?? 0 }}
                            บท</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </ng-container>
            <ng-container *ngIf="!dashboardLoading && dashboardData?.last_course.length == 0">
              <div class="no-data">
                <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
                <div class="no-data-content">
                  <h1 class="font-weight-bold mb-0">
                    ยังไม่มีคอร์สเรียน
                  </h1>
                  <small class="text-muted mb-50" [routerLink]="['/course']">
                    คลิกที่นี่เพื่อดูคอร์สเรียนที่น่าสนใจ
                  </small>
                  <a class="paginate-link">
                    <i class="fa-regular fa-plus-circle fa-2x text-primary"></i>
                  </a>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="dashboardLoading">
              <div class="course-loading">
                <div class="course-card my-50" *ngFor="let course of [0,1,4];let i as index">
                  <div class="w-100">
                    <ngx-skeleton-loader
                      [theme]="{width: '100%','border-radius': '4px',height: '140px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                  </div>
                  <div class="course-body d-block">
                    <ngx-skeleton-loader
                      [theme]="{width: '120px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      [theme]="{width: '180px','border-radius': '4px',height: '14px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      [theme]="{width: '80px','border-radius': '4px',height: '14px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <div class="row mx-0 align-items-center border-bottom pb-50">
                      <ngx-skeleton-loader
                        [theme]="{width: '180px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>

                    <div class="d-flex w-100 justify-content-between mt-50">
                      <ngx-skeleton-loader
                        [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                      <div>
                        <ngx-skeleton-loader
                          [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Right Card -->
    </div>
    <!-- Row 2 -->

    <!-- Row 2 -->
    <div class="row mx-2 mt-1 mb-0 match-height justify-content-cente momo-container">
      <!-- Card -->
      <div class="col-12">
        <div class="card no-box-shadow mb-1">
          <div class="card-header aligns-items-center"
            [ngClass]="{ 'pb-1' : !translateLoading && dashboardData?.courses.length != 0}">
            <h2 class="c-gray mb-0 font-weight-bolder">คอร์สเรียนทั้งหมด</h2>
            <a class="d-inline-flex paginate-link" [routerLink]="['/course']">
              <p class="text-primary mr-50 mb-0 ">ดูทั้งหมด</p>
              <i class="fa-regular fa-chevron-right text-primary"></i>
            </a>
          </div>
          <div class="card-body">
            <ng-container *ngIf="!dashboardLoading && dashboardData?.courses.length != 0">
              <perfect-scrollbar [config]="config">
                <div class="course-no-wrap">
                  <a [routerLink]="['/watch',course._id]" class="course-card my-50"
                    *ngFor="let course of dashboardData?.courses;let i as index">
                    <img [src]="course.image" [alt]="course.name">
                    <!-- <div *ngIf="selltomarket.relay_meta.images == undefined"
                                          class="bg-gallery no-img-items rounded-12 d-flex align-items-center justify-content-center">
                                          <i class="fa-regular fa-image text-primary fs-1"></i>
                                        </div> -->
                    <div class="course-body">
                      <h4 class="font-weight-bold text-ellipsis-1 mb-0">
                        {{ course.name }}
                      </h4>
                      <p class="text-muted text-ellipsis-2 " [ngClass]="{'mb-0' : course.description != ''}">
                        {{ course.description }}
                      </p>
                      <div class="d-flex w-100 justify-content-between mt-50">
                        <p class="text-muted mb-0">
                          <i class="fa-regular fa-clock"></i>
                          {{ course.minutes ?? '00:00' }} นาท
                        </p>
                        <div>
                          <span class="badge bg-light-primary">{{ course.total_video ?? 0 }}
                            บท</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </perfect-scrollbar>
            </ng-container>
            <ng-container *ngIf="!dashboardLoading && dashboardData?.courses.length == 0">
              <div class="no-data">
                <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
                <div class="no-data-content">
                  <h1 class="font-weight-bold mb-0">
                    ยังไม่มีคอร์สเรียน
                  </h1>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="dashboardLoading">
              <div class="course-loading">
                <div class="course-card my-50" *ngFor="let course of [0,0,0,0,0];let i as index">
                  <div class="w-100">
                    <ngx-skeleton-loader
                      [theme]="{width: '100%','border-radius': '4px',height: '140px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                  </div>
                  <div class="course-body d-block">
                    <ngx-skeleton-loader
                      [theme]="{width: '120px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      [theme]="{width: '180px','border-radius': '4px',height: '14px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      [theme]="{width: '80px','border-radius': '4px',height: '14px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <div class="row mx-0 align-items-center border-bottom pb-50">
                      <ngx-skeleton-loader
                        [theme]="{width: '180px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="d-flex w-100 justify-content-between mt-50">
                      <ngx-skeleton-loader
                        [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                      <div>
                        <ngx-skeleton-loader
                          [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Card -->
    </div>
    <!-- Row 3 -->

  </div>
</div>