import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorInterceptor, fakeBackendProvider, JwtInterceptor } from 'app/auth/helpers'

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DashboardModule } from './main/dashboard/dashboard.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { VersionControlModule } from './main/version-control/version-control.module';
import { TranslationModule } from './main/translation/translation.module';
import { CourseModule } from './main/course/course.module';
import { FileUploadModule } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';

const appRoutes: Routes = [
  {
    path: 'course',
    loadChildren: () => import('./main/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'translation',
    loadChildren: () => import('./main/translation/translation.module').then(m => m.TranslationModule)
  },
  {
    path: 'changelog',
    loadChildren: () => import('./main/version-control/version-control.module').then(m => m.VersionControlModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'dashboard',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/error/not-found' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    FileUploadModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        // Enabliong theme combination
        extendsFromRoot: true,
        // ... list of CSS theme attributes
        background: '#e2e2e2',
        'background-color': '#e2e2e2',
      },
    }),

    // App modules
    LayoutModule,
    DashboardModule,
    VersionControlModule,
    CourseModule,
    TranslationModule,
  ],

  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CookieService
  ]
})
export class AppModule { }
