import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionControlService {
  public changeList = [
    {
      version: "0.0.6",
      updated_date: "13/09/2566 11:37 น.",
      description: "update version control service",
    },
    {
      version: "0.0.5",
      updated_date: "11/09/2566 09:19 น.",
      description: "api user/price-group/china-track/deposit-order",
    },
    {
      version: "0.0.4",
      updated_date: "04/09/2566 09:48 น.",
      description: "edit manage-password/deposit-orderr",
    },
    {
      version: "0.0.3",
      updated_date: "25/08/2566 17:01 น.",
      description: "api add po_number/update estimate_date",
    },
    {
      version: "0.0.2",
      updated_date: "18/08/2566 17:31 น.",
      description: "edit design dashboard & api/design deposit order",
    },
    {
      version: "0.0.1",
      updated_date: "11/08/2566 08:00 น.",
      description: "เพิ่ม @mention แอดมิน ในหมายเหตุรายการสั่งซื้อ, ปรับ design เมนูออเดอร์สินค้า",
    },
    {
      version: "0.0.0",
      updated_date: "08/08/2566 08:00 น.",
      description: "ปรับ Design และ ต่อ Api",
    }
  ]

  constructor() { }

  getChangeList() {
    return this.changeList;
  }

  getLastVersion() {
    return this.changeList[0].version;
  }

}
