import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private cookieService: CookieService
  ) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const localUser = JSON.parse(localStorage.getItem('_mus'));
    // this._authService.currentUser.subscribe(x => (this.currentUser = x.datauser));
    const currentUser = this._authService.currentUserValue;
    this._authService.getLiveProfile().subscribe(x => {
      // this._authenticationService.updateCurrentUser();
      setTimeout(() => {
        if (currentUser) {
          if (state.url.includes('/translation') && currentUser.datauser.momoclass != 'advance') {
            this._router.navigate(['/dashboard']);
            return false;
          }

          if (currentUser.datauser.momoclass == 'none') {
            window.location.href = 'https://customer.momocargo.com/momo-class';
          }
          // authorised so return true
          return true;
        } else {
          window.location.reload();
          // window.location.href = 'https://customer.momocargo.com/momo-class';
          return false;
        }
      }, 1000);
    });

    if (localUser) {
      if (state.url.includes('/translation') && currentUser.datauser.momoclass != 'advance') {
        this._router.navigate(['/dashboard']);
        return false;
      }

      if (currentUser.datauser.momoclass == 'none') {
        localStorage.removeItem('_mus');
        window.location.href = 'https://customer.momocargo.com/momo-class';
      }
      // authorised so return true
      return true;
    }
    // else {
    //   window.location.href = 'https://customer.momocargo.com/momo-class';
    //   return false;
    // }
    // this._router.navigate(['/error/not-authorized']);

    // not logged in so redirect to login page with the return url
    // this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    // window.location.href = 'https://momocargo-customer.web.app/auth/login';
    return false;
  }

}
