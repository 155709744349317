
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FileUploadModule } from 'ng2-file-upload';

import { AuthGuard } from 'app/auth/helpers'
import { CommonModule } from '@angular/common';
import { TranslationComponent } from './translation.component';
import { TranslationService } from './translation.service';
import { CourseService } from '../course/course.service';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { S3Service } from '@core/services/s3.service';

const routes = [
  {
    path: '',
    component: TranslationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  providers: [TranslationService, CourseService, StripHtmlPipe, S3Service],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes),
    ContentHeaderModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    FileUploadModule
  ],
  declarations: [TranslationComponent]
})
export class TranslationModule { }
