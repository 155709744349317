import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation } from './translation';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private _httpClient: HttpClient) {
  }

  getTranslateList(page: number, limit: number, type: string): Observable<Translation> {
    return this._httpClient.get<any>(`/translator/get/${page}/${limit}/${type}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  getTranslate(page: number, limit: number): Observable<any> {
    return this._httpClient.get<any>(`/translator/user/get/question/${page}/${limit}`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  postTranslation(body: any): Observable<any> {
    return this._httpClient.post<any>(`/translator/create`, body).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    return throwError('เกิดเหตุขัดข้อง กรุณาลองใหม่อีกครั้งในภายหลัง');
  }

  httpOptions(body: any) {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token')),
      body: body
    }
  }

}
