import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { CourseService } from './course.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnDestroy {
  public page = 1;
  public pageSize = 15;
  public collectionSize = 0;
  public type = 'all';

  public contentHeader: object;
  public courseList;
  public courseLoading = false;

  public suggestionList;
  public suggestionLoading = false;

  private _unsubscribeAll = new Subject<any>();

  constructor(private _courseService: CourseService, private _toastrService: ToastrService) {
  }

  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'คอร์สทั้งหมด',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'หน้าแรก',
            isLink: true,
            link: '/'
          },
          {
            name: 'คอร์สทั้งหมด',
            isLink: false
          }
        ]
      }
    }

    this.getSuggesstionList();
    this.getCourseList();
  }

  getCourseList() {
    this.courseLoading = true;
    this._courseService.getCategoryList(this.page, this.pageSize, this.type).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.courseList = response.data;
        this.collectionSize = response.data.total_data;
      }
      this.courseLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      this.courseLoading = false;
    })
  }

  getSuggesstionList() {
    this.suggestionLoading = true;
    this._courseService.getCategoryList(1, 5, this.type).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.suggestionList = response.data;
      }
      this.suggestionLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      this.suggestionLoading = false;
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
