import { map, finalize, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { from } from 'rxjs';
import { S3Client, UploadPartCommand, PutObjectCommand, DeleteObjectCommand, ListObjectsV2Command, CreateMultipartUploadCommand, CompleteMultipartUploadCommand, AbortMultipartUploadCommand } from '@aws-sdk/client-s3';
import { fileURLToPath } from "url";
import { Upload } from "@aws-sdk/lib-storage";

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private client: S3Client;
  public progress = new Subject<number>();

  constructor() {
    this.client = new S3Client({
      region: environment.aws.region,
      credentials: {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey
      }
    });
  }

  async getFileList(bucket: string) {
    // set getlist command parameter
    const command = new ListObjectsV2Command({
      Bucket: bucket,
      // max key return
      MaxKeys: 2,
    });

    try {
      let isTruncated = true;

      console.log("Your bucket contains the following objects:\n");
      let contents = "";

      while (isTruncated) {
        const { Contents, IsTruncated, NextContinuationToken } =
          await this.client.send(command);
        const contentsList = Contents.map((c) => ` • ${c.Key}`).join("\n");
        contents += contentsList + "\n";
        isTruncated = IsTruncated;
        command.input.ContinuationToken = NextContinuationToken;
      }
      console.log(contents);
    } catch (err) {
      console.error(err);
    }
  };

  async deleteFile(filePath: any) {
    let pathSplit = filePath.split('/');
    console.log('pathSplit', pathSplit);
    // set put command parameter
    const command = new DeleteObjectCommand({
      Bucket: environment.aws.bucketName,
      Key: pathSplit[pathSplit.length - 2] + '/' + pathSplit[pathSplit.length - 1]
    });

    console.log('command', command);

    // try {
    //   const response = await this.client.send(command);
    //   console.log(response);
    //   return `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`;
    // } catch (err) {
    //   console.error(err);
    //   return;
    // }
  }

  async uploadFile(file: any, key: string) {
    let arr_type = file.type.split('/');
    let type = arr_type[arr_type.length - 1];
    let finalKey = key + '/' + Date.now() + '.' + type;

    // set put command parameter
    const command = new PutObjectCommand({
      Bucket: environment.aws.bucketName,
      Key: finalKey,
      Body: file,
      ContentType: file.type
    });

    try {
      const response = await this.client.send(command);
      console.log(response);
      return `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  twentyFiveMB = 25 * 1024 * 1024;
  createString(size = this.twentyFiveMB) {
    return "x".repeat(size);
  }

  async uploadLargeFile(file: any, key: string) {
    let arr_type = file.type.split('/');
    let type = arr_type[arr_type.length - 1];
    let finalKey = key + '/' + Date.now() + '.' + type;
    let uploadId;
    const str = this.createString();
    const buffer = Buffer.from(str, "utf8");
    try {
      const multipartUpload = await this.client.send(
        new CreateMultipartUploadCommand({
          Bucket: environment.aws.bucketName,
          Key: finalKey,
        }),
      );

      uploadId = multipartUpload.UploadId;

      const uploadPromises = [];
      // Multipart uploads require a minimum size of 5 MB per part.
      const partSize = Math.ceil(buffer.length / 5);

      // Upload each part.
      for (let i = 0; i < 5; i++) {
        const start = i * partSize;
        const end = start + partSize;
        uploadPromises.push(
          this.client
            .send(
              new UploadPartCommand({
                Bucket: environment.aws.bucketName,
                Key: key,
                UploadId: uploadId,
                Body: buffer.subarray(start, end),
                PartNumber: i + 1,
              }),
            )
            .then((d) => {
              console.log("Part", i + 1, "uploaded");
              return d;
            }),
        );
      }

      const uploadResults = await Promise.all(uploadPromises);

      return await this.client.send(
        new CompleteMultipartUploadCommand({
          Bucket: environment.aws.bucketName,
          Key: key,
          UploadId: uploadId,
          MultipartUpload: {
            Parts: uploadResults.map(({ ETag }, i) => ({
              ETag,
              PartNumber: i + 1,
            })),
          },
        }),
      );

      // Verify the output by downloading the file from the Amazon Simple Storage Service (Amazon S3) console.
      // Because the output is a 25 MB string, text editors might struggle to open the file.
    } catch (err) {
      console.error(err);

      if (uploadId) {
        const abortCommand = new AbortMultipartUploadCommand({
          Bucket: environment.aws.bucketName,
          Key: key,
          UploadId: uploadId,
        });

        await this.client.send(abortCommand);
      }
    }
  };

}


  // async uploadFile(file: any, key: string) {
  //   let arr_type = file.type.split('/');
  //   let type = arr_type[arr_type.length - 1];
  //   let finalKey = key + '/' + Date.now() + '.' + type;

  //   const uploadParams = {
  //     Bucket: environment.aws.bucketName,
  //     Key: finalKey,
  //     Body: file,
  //     ContentType: file.type
  //   };

  //   // Use S3 ManagedUpload class as it supports multipart uploads
  //   const command = new PutObjectCommand(uploadParams);

  //   try {
  //     const response = await this.client.send(command);
  //     console.log(response);
  //     return `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`;

  //   } catch (err) {
  //     console.error(err);
  //     return;
  //   }

  //   // try {
  //   //   const parallelUploads3 = new Upload({
  //   //     client: this.client,
  //   //     params: uploadParams,

  //   //     // tags: [
  //   //     //   /*...*/
  //   //     // ], // optional tags
  //   //     // queueSize: 4, // optional concurrency configuration
  //   //     // partSize: 1024 * 1024 * 5, // optional size of each part, in bytes, at least 5MB
  //   //     leavePartsOnError: true, // optional manually handle dropped parts
  //   //   });

  //   //   parallelUploads3.on("httpUploadProgress", (progress) => {
  //   //     this.progress.next((progress.loaded / progress.total) * 100);
  //   //     console.log(progress);
  //   //   });

  //   //   // async () => {
  //   //   await parallelUploads3.done();
  //   //   // }

  //   //   // .then(res => {
  //   //   // this.progress.next(100);
  //   //   // });
  //   //   return `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`;

  //   //   // return this.progress.asObservable().pipe(
  //   //   //   filter((percentage) => percentage !== undefined),
  //   //   //   take(1),
  //   //   //   map(() => `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`)
  //   //   // );
  //   // } catch (e) {
  //   //   console.log(e);
  //   // }

  // }


  // public uploadFile(file: any, key: string): Observable<string> {
  //   let arr_type = file.type.split('/');
  //   let type = arr_type[arr_type.length - 1];
  //   let finalKey = key + '/' + Date.now() + '.' + type;

  //   const uploadParams = {
  //     Bucket: environment.aws.bucketName,
  //     Key: finalKey,
  //     Body: file,
  //     ContentType: file.type
  //   };

  //   const uploadCommand = new PutObjectCommand(uploadParams);
  //   const managedUpload = new ManagedUpload({
  //     params: uploadParams,
  //   });

  //   const progress = new Subject<number>();

  //   managedUpload.on('httpUploadProgress', (progressEvent) => {
  //     progress.next((progressEvent.loaded / progressEvent.total) * 100);
  //   });

  //   return new Observable<string>((observer) => {
  //     managedUpload.send((err, data) => {
  //       if (err) {
  //         observer.error(err);
  //       } else {
  //         observer.next(`https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`);
  //         observer.complete();
  //       }
  //     });
  //   }).pipe(finalize(() => progress.complete()));
  // }

  // public deleteFile(key: string): Observable<any> {
  //   const deleteParams = {
  //     Bucket: environment.aws.bucketName,
  //     Key: key
  //   };

  //   const deleteCommand = new DeleteObjectCommand(deleteParams);
  //   return from(this.s3Client.send(deleteCommand));
  // }

  // private s3: S3;

  // constructor() {
  //   const credentials = new Credentials({
  //     accessKeyId: environment.aws.accessKeyId,
  //     secretAccessKey: environment.aws.secretAccessKey
  //   });
  //   this.s3 = new S3({ credentials, region: environment.aws.region });
  // }

  // public uploadFile(file: any, key: string): Observable<string> {
  //   let arr_type = file.type.split('/');
  //   let type = arr_type[arr_type.length - 1];
  //   let finalKey = key + '/' + Date.now() + '.' + type;

  //   const uploadParams = {
  //     Bucket: environment.aws.bucketName,
  //     Key: finalKey,
  //     Body: file,
  //     ContentType: file.type
  //   };

  //   const progress = new Subject<number>();
  //   const upload = this.s3.upload(uploadParams);
  //   upload.on('httpUploadProgress', (progressEvent) => {
  //     progress.next((progressEvent.loaded / progressEvent.total) * 100);
  //   });
  //   upload.send((error, data) => {
  //     if (error) {
  //       progress.error(error);
  //     } else {
  //       progress.complete();
  //     }
  //   });

  //   return progress.asObservable().pipe(
  //     filter((percentage) => percentage !== undefined),
  //     take(1),
  //     map(() => `https://${environment.aws.bucketName}.s3.${environment.aws.region}.amazonaws.com/${finalKey}`)
  //   );
  // }


