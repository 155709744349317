<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer">
  <a class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')">
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->

<!-- Viewport Helper -->
<!-- <div style="position:fixed;top:0;left:0;background-color:rgba(0,0,0,0.5);padding:10px;color:#fff;z-index: 999;">
  <span class="d-sm-none">SIZE XS</span>
  <span class="d-none d-sm-block d-md-none">SIZE SM</span>
  <span class="d-none d-md-block d-lg-none">SIZE MD</span>
  <span class="d-none d-lg-block d-xl-none">SIZE LG</span>
  <span class="d-none d-xl-block d-xxl-none ">SIZE XL</span>
  <span class="d-none d-xxl-block ">SIZE XXL</span>
</div> -->
<!-- Viewport Helper -->