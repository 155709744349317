<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex  momo-container">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <a class="navbar-brand" [routerLink]="['/']">
          <span class="brand-logo">
            <img src="assets/images/landing/momo-landing-logo.png" alt="brand-logo" height="62" />
          </span>
        </a>
      </li>

    </ul>
  </div>
  <!-- <ul class="nav navbar-nav align-items-center ml-auto pr-1">
    <li class="nav-item">
      <a [routerLink]="['/dashboard']" class="nav-link  text-white fs-20" routerLinkActive="active">หน้าแรก</a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/course']" class="nav-link  text-white fs-20" routerLinkActive="active">คอร์สเรียนทั้งหมด</a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/translation']" class="nav-link  text-white fs-20" routerLinkActive="active">แปลภาษาไทย -
        จีน</a>
    </li>
  </ul> -->
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user my-1">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container>
          <div class="user-nav d-sm-flex d-none ml-50">
            <ng-container *ngIf="currentUser?.name">
              <span class="user-name font-weight-normal fs-4">
                {{ currentUser?.user_code }} : {{ currentUser?.name }}
              </span>
            </ng-container>
            <ng-container *ngIf="!currentUser?.name">
              <span class="user-name font-weight-normal fs-4" *ngIf="currentUser?.phone != ''">
                {{ currentUser?.user_code }} : {{ currentUser?.phone}}
              </span>
              <span class="user-name font-weight-normal fs-4" *ngIf="currentUser?.phone == ''">
                {{ currentUser?.user_code }} : {{ currentUser?.email}}
              </span>
            </ng-container>

            <div class="badge-role px-50 badge-light-secondary bg-white">
              <span class="role-fs text-primary">
                <!-- {{ currentUser?.momoclass }} -->
                {{ getPackageName() }}
              </span>
            </div>
          </div>
          <span class="avatar">
            <img *ngIf="currentUser?.avatar !== null && currentUser?.avatar !== ''" class="round no-box-shadow"
              [src]="currentUser.avatar" alt="avatar" height="40" width="40" />
            <div *ngIf="!currentUser?.avatar"
              class="no-avatar-user rounded-circle d-flex align-items-center justify-content-center ">
              <span class="fa-regular fa-user  text-primary fs-4"></span>
            </div>
          </span>
          <i class="fa-regular fa-chevron-down ml-50 chev-profile"></i>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/dashboard']" routerLinkActive="active">
          <span [data-feather]="'pie-chart'" [class]="'mr-50 align-self-center'"></span>
          หน้าแรก
        </a>
        <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/course']">
          <span [data-feather]="'list'" [class]="'mr-50 align-self-center'"></span>
          คอร์สเรียนทั้งหมด
        </a>
        <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/translation']"
          *ngIf="currentUser?.momoclass == 'advance'">
          <!-- <span [data-feather]="'list'" [class]="'mr-50 align-self-center'"></span> -->
          <i class="fa-regular fa-language mr-50 align-self-center"></i>
          แปลภาษาไทย - จีน
        </a>
        <!-- <a class="fs-4 d-flex" ngbDropdownItem [routerLink]="['/profile/information']">
          <span [data-feather]="'user'" [class]="'mr-50 align-self-center'"></span>
          ข้อมูลส่วนตัว
        </a> -->
        <a class="d-flex fs-4" ngbDropdownItem (click)="onLogoutModal(logoutModal)">
          <span [data-feather]="'power'" [class]="'mr-50 align-self-center'"></span>
          ออกจากระบบ</a>
        <ng-template #logoutModal let-modal>
          <div class="modal-body text-center p-2">
            <i class="fa-regular fa-arrow-right-from-bracket c-gray fa-4x"></i>
            <h2 class="fs-2 c-gray mb-1">
              คุณยืนยันที่จะออกจากระบบ หรือไม่</h2>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-close-modal btn-modal  mb-1 "
              (click)="modal.close('ยกเลิก')">ยกเลิก</button>
            <button type="button" class="btn btn-confirm-modal btn-modal mx-0 mb-1"
              (click)="logout();modal.close('logout')">
              <span class="fs-4 btn-text">ยืนยัน</span>
            </button>
          </div>
        </ng-template>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>