<div class="content-wrapper p-0">
  <div class="content-body mb-5">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Row 1 -->
    <div class="row mx-2 mt-1 mb-0 justify-content-cente momo-container">
      <!-- Left Card -->
      <div class="col-xl-7 col-12 pr-xl-50 order-1 order-xl-0 ">
        <div class="card no-box-shadow mb-1">
          <div class="card-header aligns-items-center">
            <h2 class="c-gray mb-0 font-weight-bolder">แปลไทย-จีนของฉัน</h2>
          </div>
          <div class="card-body">
            <div class="input-group input-group-merge mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text" id="search"><i class="fa-solid fa-magnifying-glass"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="ค้นหาประโยค..." aria-label="ค้นหาประโยค..."
                aria-describedby="search" />
            </div>
            <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill">
              <li ngbNavItem>
                <a ngbNavLink><i class="fa-regular fa-list mr-50"></i> คำแปลทั้งหมด</a>
                <ng-template ngbNavContent>
                  <ng-container *ngIf="listTranslateData?.data.data.length > 0 && !listTranslateLoading">
                    <div class="collapse-icon">
                      <ngb-accordion [closeOthers]="true" (shown)='panelExpanded($event, listTranslatePanel)'
                        (hidden)="panelCollapse($event, listTranslatePanel)" #listTranslatePanel>
                        <ngb-panel [disabled]="trans.answer == ''" *ngFor="let trans of listTranslateData.data.data">
                          <ng-template ngbPanelTitle>
                            <span class="collapse-title">
                              <i class="fa-light fa-language fa-2x text-dark"></i>
                              <div class="collapse-content mr-1">
                                <h4 class="text-secondary mb-0 pr-1">
                                  {{ trans.question | striphtml }}
                                </h4>
                                <p class="collapse-status mb-0">
                                  <ng-container *ngIf="trans.answer == ''">
                                    <i class="fa-solid fa-clock text-warning fa-xs"></i>
                                    โปรดรอ
                                  </ng-container>
                                  <ng-container *ngIf="trans.answer != ''">
                                    <i class="fa-solid fa-circle-check text-success fa-xs"></i>
                                    แปลแล้ว
                                  </ng-container>
                                </p>
                              </div>
                            </span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <p>
                              {{ trans.answer | striphtml }}
                            </p>
                            <a class="paginate-link text-dark" (click)="copyCode(trans.answer)">
                              <i class="fa-regular fa-copy text-dark"></i>
                              คัดลอก
                            </a>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                    <div class="col-12 d-flex flex-wrap justify-content-center align-items-center mt-2">
                      <div class="custom-pagination">
                        <ngb-pagination [boundaryLinks]="true" [maxSize]="5" [ellipses]="false"
                          [collectionSize]="listCollectionSize" [(page)]="listPage" [pageSize]="listPageSize"
                          (pageChange)="getTranslateList()">
                          <ng-template ngbPaginationFirst><i class="fa-regular fa-chevron-double-left"></i>
                          </ng-template>
                          <ng-template ngbPaginationLast><i class="fa-regular fa-chevron-double-right"></i>
                          </ng-template>
                          <ng-template ngbPaginationPrevious><i class="fa-regular fa-chevron-left"></i></ng-template>
                          <ng-template ngbPaginationNext><i class="fa-regular fa-chevron-right"></i></ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!listTranslateLoading && listTranslateData?.data.data.length == 0">
                    <div class="no-data">
                      <i class="fa-regular fa-language fa-3x text-dark"></i>
                      <div class="no-data-content">
                        <h1 class="font-weight-bold mb-0">
                          ยังไม่มีการถาม-แปลภาษาล่าสุด
                        </h1>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!listTranslateLoading && !listTranslateData">
                    <div class="no-data">
                      <i class="fa-regular fa-wifi-slash fa-3x text-dark"></i>
                      <div class="no-data-content">
                        <h1 class="font-weight-bold mb-0 text-center">
                          เกิดเหตุขัดข้อง<br>กรุณาลองใหม่อีกครั้งในภายหลัง
                        </h1>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink><i class="fa-regular fa-language mr-50"></i> คำแปลของฉัน</a>
                <ng-template ngbNavContent>
                  <ng-container *ngIf="translateData?.data.data.length > 0">

                    <div class="collapse-icon">
                      <ngb-accordion [closeOthers]="true">
                        <ngb-panel [disabled]="trans.answer == ''" *ngFor="let trans of translateData.data.data">
                          <ng-template ngbPanelTitle>
                            <span class="collapse-title">
                              <i class="fa-light fa-language fa-2x text-dark"></i>
                              <div class="collapse-content mr-1">
                                <h4 class="text-secondary mb-0 pr-1">
                                  {{ trans.question | striphtml }}
                                </h4>
                                <p class="collapse-status mb-0">
                                  <ng-container *ngIf="trans.answer == ''">
                                    <i class="fa-solid fa-clock text-warning fa-xs"></i>
                                    โปรดรอ
                                  </ng-container>
                                  <ng-container *ngIf="trans.answer != ''">
                                    <i class="fa-solid fa-circle-check text-success fa-xs"></i>
                                    แปลแล้ว
                                  </ng-container>
                                </p>
                              </div>
                            </span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <p>
                              {{ trans.answer | striphtml }}
                            </p>
                            <a class="paginate-link text-dark" (click)="copyCode(trans.answer)">
                              <i class="fa-regular fa-copy text-dark"></i>
                              คัดลอก
                            </a>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                    </div>
                    <div class="col-12 d-flex flex-wrap justify-content-center align-items-center mt-2">
                      <div class="custom-pagination">
                        <ngb-pagination #numPages [boundaryLinks]="true" [maxSize]="5" [ellipses]="false"
                          [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                          (pageChange)="getTranslate()">
                          <ng-template ngbPaginationFirst><i class="fa-regular fa-chevron-double-left"></i>
                          </ng-template>
                          <ng-template ngbPaginationLast><i class="fa-regular fa-chevron-double-right"></i>
                          </ng-template>
                          <ng-template ngbPaginationPrevious><i class="fa-regular fa-chevron-left"></i></ng-template>
                          <ng-template ngbPaginationNext><i class="fa-regular fa-chevron-right"></i></ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!translateLoading && translateData?.data.data.length == 0">
                    <div class="no-data">
                      <i class="fa-regular fa-language fa-3x text-dark"></i>
                      <div class="no-data-content">
                        <h1 class="font-weight-bold mb-0">
                          ยังไม่มีการแปลที่ถูกส่งโดยคุณ
                        </h1>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!translateLoading && !translateData">
                    <div class="no-data">
                      <i class="fa-regular fa-wifi-slash fa-3x text-dark"></i>
                      <div class="no-data-content">
                        <h1 class="font-weight-bold mb-0 text-center">
                          เกิดเหตุขัดข้อง<br>กรุณาลองใหม่อีกครั้งในภายหลัง
                        </h1>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>

            <div class="collapse-icon mt-3" *ngIf="(listTranslateLoading || translateLoading) ">
              <!-- <ngb-accordion [closeOthers]="true">
                <ngb-panel [disabled]="true" *ngFor="let item of [0,0,0,0,0,0,0,0,0,0]; let i as index;">
                  <ng-template ngbPanelTitle>
                    <span class="collapse-title">
                      <ngx-skeleton-loader
                        [theme]="{width: '50px','border-radius': '8px',height: '40px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                      <div class="collapse-content mr-1">
                        <h4 class="text-secondary mb-0 pr-1">
                          <ngx-skeleton-loader
                            [theme]="{width: '180px','border-radius': '16px',height: '20px','margin-bottom': '10px'}">
                          </ngx-skeleton-loader>
                        </h4>
                        <p class="collapse-status mb-0">
                          <ngx-skeleton-loader
                            [theme]="{width: '80px','border-radius': '16px',height: '10px','margin-bottom': '0px'}">
                          </ngx-skeleton-loader>
                        </p>
                      </div>
                    </span>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion> -->
              <div class="collapse-icon" style="overflow:hidden">
                <div *ngFor="let trans of [0,0,0,0,0,0,0,0,0,0];">
                  <span class="collapse-title">
                    <ngx-skeleton-loader
                      [theme]="{width: '50px','border-radius': '4px',height: '50px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <div class="collapse-content mr-1">
                      <ngx-skeleton-loader
                        [theme]="{width: '140px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                      <p class="collapse-status mb-0">
                        <ngx-skeleton-loader
                          [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </p>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-12 d-flex flex-wrap justify-content-center align-items-center mt-2">
                <div class="custom-pagination">
                  <ngx-skeleton-loader
                    [theme]="{width: '250px','border-radius': '16px',height: '40px','margin-bottom': '0px'}">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--/ Left Card -->

      <!-- Right Card -->
      <div class="col-xl-5 col-12 pl-xl-50 order-0 order-xl-1">
        <div class="row">
          <div class="col-12">
            <div class="card no-box-shadow mb-1">
              <div class="card-header aligns-items-center">
                <h2 class="c-gray mb-0 font-weight-bolder">ส่งประโยคให้ล่ามจีนแปล ฟรี!</h2>
              </div>
              <div class="card-body">
                <form [formGroup]="translationForm" (submit)="onSaveTranslator()" class="form-group">
                  <input type="text" class="form-control" placeholder="ตัวอย่างเช่น เคสโทรศัพท์มือถือ"
                    aria-label="ตัวอย่างเช่น เคสโทรศัพท์มือถือ" aria-describedby="search" formControlName="title"
                    [ngClass]="{ 'is-invalid' : (title.invalid && (title.dirty || title.touched) )}" />
                  <div *ngIf="title.invalid && (title.dirty || title.touched)" class="text-danger">
                    <div *ngIf="title.errors?.required">
                      Title is required.
                    </div>
                    <div *ngIf="title.errors?.minlength">
                      Title must be at least 1 characters long.
                    </div>
                  </div>

                  <div class="drop-zone my-1" ng2FileDrop [ngClass]="{'drop-file-over': hasBaseDropZoneOver}"
                    (fileOver)="fileOverBase($event)" (onFileDrop)="onImagePicked('drop',$event)" [uploader]="uploader"
                    *ngIf="!imagePreview">
                    <div class="drop-content">
                      <i class="fa-regular fa-cloud-arrow-up fa-xl mb-50"></i>
                      <p class="text-center mb-0">ลากและวางไฟล์เพื่ออัพโหลดที่นี่รองรับ JPG, PNG <br> หรือ
                        <span class="text-primary cursor-pointer"
                          (click)="filePicker.click()">เลือกรูปจากคอมพิวเตอร์ของคุณ</span>
                      </p>
                    </div>
                  </div>
                  <div class="drop-zone my-1" *ngIf="imagePreview" [ngClass.preview]="imagePreview">
                    <img [src]="imagePreview" alt="" class="w-100 rounded">
                  </div>
                  <input type="file" #filePicker class="d-none" (change)="onImagePicked('picker',$event)">
                  <button class="btn btn-primary btn-block" type="submit"
                    [disabled]="!translationForm.valid">ส่งประโยคให้ล่ามจีนแปล</button>
                  <small class="text-muted">หมายเหตุ: หากคุณสงสัยประโยคจีนหรือไทยจะถามเมื่อไรก็ได้
                    สอบถามได้ไม่จำกัด</small>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card no-box-shadow mb-1">
              <div class="card-header aligns-items-center pb-1">
                <h2 class="c-gray mb-0 font-weight-bolder">คอร์สออนไลน์ที่น่าสนใจ</h2>
              </div>
              <div class="card-body">
                <ng-container *ngIf="courseList?.data.length != 0 && !courseLoading">
                  <div class="course-list-group row">
                    <a [routerLink]="['/watch',course._id]"
                      class="course-list-group-item list-group-item-action item-rounded col-12"
                      *ngFor="let course of courseList?.data;let i as index">
                      <div class="row mx-0">
                        <div class="col px-0">
                          <div class="mr-1">
                            <img [src]="course.image" alt="" class="w-100 rounded">
                          </div>
                        </div>
                        <div class="col-6 col-sm-8 px-0">
                          <h5 class="c-gray mb-0 font-weight-bolder text-ellipsis-2">{{ course.name }}</h5>
                          <div class="d-flex w-100 justify-content-between">
                            <p class="text-muted mb-0 text-ellipsis-1">
                              <i class="fa-regular fa-clock"></i>
                              {{ course.minutes }} นาที
                            </p>
                            <div>
                              <span class="badge bg-light-primary">{{course.total_video }} บท</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </ng-container>

                <ng-container *ngIf="!courseLoading && courseList?.data.length == 0">
                  <div class="no-data">
                    <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
                    <div class="no-data-content">
                      <h1 class="font-weight-bold mb-0">
                        ยังไม่มีคอร์สเรียน
                      </h1>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="courseLoading">
                  <div class="course-list-group row">
                    <a href="javascript:void(0)"
                      class="course-list-group-item list-group-item-action item-rounded col-12"
                      *ngFor="let item of [0,1,2,3,4];let i as index">
                      <div class="row mx-0">
                        <div class="col px-0">
                          <div class="mr-1">
                            <ngx-skeleton-loader
                              [theme]="{width: '100%','border-radius': '16px',height: '4.375rem','margin-bottom': '0px'}">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                        <div class="col-6 col-sm-8 px-0">
                          <div class="mb-0">
                            <ngx-skeleton-loader
                              [theme]="{width: '100%','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader
                              [theme]="{width: '160px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                            </ngx-skeleton-loader>
                          </div>
                          <div class="d-flex w-100 justify-content-between">
                            <p class="mb-0">
                              <ngx-skeleton-loader
                                [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                              </ngx-skeleton-loader>
                            </p>
                            <ngx-skeleton-loader
                              [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Card -->
    </div>
    <!-- Row 1 -->

  </div>
</div>