<div class="content-wrapper p-0 bg-white">
  <div class="content-body">
    <div class="row match-height mx-0 h-100">

      <div class="col-md-12 col-lg-3 px-0 border-right order-1 order-lg-0">
        <perfect-scrollbar class="vdo-scroll-bar">
          <ng-container *ngIf="videoList && !listLoading">
            <div class="m-2">
              <h3 class="c-gray mb-0 font-weight-bolder">หมวดที่ {{ currentCategory.cate_id }} {{ currentCategory.name
                }}
              </h3>
              <div class="progress-wrapper pt-2">
                <ngb-progressbar type="gradient-primary" class="progress-bar-primary" [value]="courseProgress">
                </ngb-progressbar>
              </div>
              <div class="d-flex justify-content-between mt-50 mx-0 align-items-center">
                <h3 class="c-gray mb-0">สำเร็จแล้ว: {{ courseProgress }}%</h3>
                <span class="badge bg-light-primary">{{ courseComplete }}/{{ videoList.length }} บท</span>
              </div>
            </div>
            <div class="menu-list-group">
              <ng-container *ngFor="let item of videoList; let i as index">
                <a [routerLink]="['/course/watch/',item.category._id,item._id]" routerLinkActive="active"
                  class="menu-list-group-item list-group-item-action">
                  <div class="d-flex mx-0">
                    <ng-container *ngIf="currentVideo">
                      <ng-container *ngIf="item._id !== currentVideo._id">
                        <i class="justify-content-start fa-regular fa-circle text-secondary mr-50"
                          *ngIf="item.status === 0"></i>
                        <i class="justify-content-start fa-regular fa-pause text-secondary mr-50"
                          *ngIf="item.status === 1"></i>
                        <i class="justify-content-start fa-regular fa-circle-check text-success mr-50"
                          *ngIf="item.status === 2"></i>
                      </ng-container>
                      <ng-container *ngIf="item._id === currentVideo._id">
                        <i class="justify-content-start fa-solid fa-circle-play text-gradient-primary mr-50"></i>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!currentVideo">
                      <i class="justify-content-start fa-regular fa-circle text-secondary mr-50"
                        *ngIf="item.status === 0"></i>
                      <i class="justify-content-start fa-regular fa-pause text-secondary mr-50"
                        *ngIf="item.status === 1"></i>
                      <i class="justify-content-start fa-regular fa-circle-check text-success mr-50"
                        *ngIf="item.status === 2"></i>
                    </ng-container>

                    <div class="px-0">
                      <p class="mb-0">
                        บทที่ {{ item.sort_number }}
                      </p>
                      <h5 class="c-gray mt-0 font-weight-bolder text-ellipsis-2">{{ item.name }}</h5>
                      <span class="badge bg-light-primary">{{ item.minutes ?? 0 }} นาที</span>
                    </div>
                  </div>
                </a>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="listLoading">
            <div class="m-2 mt-5 mt-md-2">
              <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '16px',height: '30px'}">
              </ngx-skeleton-loader>
              <div class="progress-wrapper pt-2">
                <ngx-skeleton-loader
                  [theme]="{width: '100%','border-radius': '16px',height: '40px','margin-bottom': '0px'}">
                </ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-between mt-50 mx-0 align-items-center">
                <ngx-skeleton-loader
                  [theme]="{width: '100px','border-radius': '16px',height: '20px','margin-bottom': '0px'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  [theme]="{width: '100px','border-radius': '16px',height: '20px','margin-bottom': '0px'}">
                </ngx-skeleton-loader>
              </div>
            </div>
            <div class="course-list-group row mx-0">
              <a href="javascript:void(0)" class="course-list-group-item list-group-item-action item-rounded col-12"
                *ngFor="let item of [0,1,2,3,4];let i as index">
                <div class="row mx-0">
                  <div class="col px-0">
                    <div class="mr-1">
                      <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                  </div>
                  <div class="col-10 px-0">
                    <div class="mb-0">
                      <ngx-skeleton-loader
                        [theme]="{width: '40px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="mb-0">
                      <ngx-skeleton-loader
                        [theme]="{width: '160px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="">
                      <p class="mb-0">
                        <ngx-skeleton-loader
                          [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="videoList?.length == 0 && !listLoading">
            <div class="no-data">
              <div class="no-data-content">
                <i class="fa-sharp fa-solid fa-film-slash fa-2x"></i>
                <h3>ยังไม่มีวิดีโอในคอร์ส</h3>
              </div>
            </div>
          </ng-container>
        </perfect-scrollbar>
        <div class="fixed-menu d-none d-lg-block">
          <a class="fixed-menu-item" routerLink="/translation" *ngIf="currentUser.datauser.momoclass == 'advance'">
            <i class="fa-regular fa-message-lines mr-50"></i>
            <h5 class="mb-0">สอบถามล่ามจีนโดยตรง</h5>
          </a>
          <a class="fixed-menu-item" href="https://customer.momocargo.com/home">
            <i class="fa-regular fa-arrow-right-from-bracket mr-50 text-primary"></i>
            <h5 class="text-primary mb-0">ออกจากห้องเรียน</h5>
          </a>
        </div>
      </div>

      <div class="col-md-12 col-lg-6 px-0 order-0 order-lg-1">
        <div class="m-2 h-100">

          <ng-container *ngIf="currentVideo && !videoLoading">
            <h2 class="c-gray mb-0 font-weight-bolder mb-1">บทที่ {{ currentVideo.sort_number }}: {{ currentVideo.name
              }}</h2>
            <!-- plyr video -->
            <app-video-player [videoLink]="videoLink || currentVideo.video_link" [currentVideo]="currentVideo">
            </app-video-player>
            <!--/ plyr video -->
            <div id="prevVideo" class="d-flex justify-content-between mt-50 mx-0 align-items-center"
              *ngIf="!paginationLoading">
              <a class="paginate-link d-flex align-items-center justify-content-start" *ngIf="prevVideo != ''"
                [routerLink]="['/course/watch/',courseId,prevVideo]">
                <i class="fa-regular fa-chevron-left text-primary"></i>
                <h5 class="c-gray mb-0 ml-50">บทเรียนก่อนหน้า</h5>
              </a>
              <a id="nextVideo" class="paginate-link d-flex align-items-center justify-content-end ml-auto"
                *ngIf="nextVideo != ''" [routerLink]="['/course/watch/',courseId,nextVideo]">
                <h5 class="c-gray mb-0 mr-50">บทเรียนถัดไป</h5>
                <i class="fa-regular fa-chevron-right text-primary"></i>
              </a>
            </div>
          </ng-container>


          <ng-container *ngIf="videoList?.length == 0 && !videoLoading">
            <div class="no-data mt-md-5 py-5 border-bottom">
              <div class="no-data-content">
                <!-- <i class="fa-solid fa-arrow-pointer fa-2x"></i>
                <h3>กรุณาเลือกวิดีโอที่ต้องการชม</h3> -->
                <i class="fa-sharp fa-solid fa-film-slash fa-2x"></i>
                <h3>ยังไม่มีวิดีโอในคอร์ส</h3>
              </div>
              <div class="d-flex justify-content-center mt-50 mx-0 align-items-center ">
                <a class="paginate-link d-flex align-items-center justify-content-end ml-auto mt-3"
                  [routerLink]="['/course/']">
                  <i class="fa-regular fa-chevron-left text-primary"></i>
                  <h5 class="c-gray mb-0 ml-50">ย้อนกลับไปเลือกคอร์สใหม่่</h5>
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="videoLoading">
            <div class="mb-0 mb-1">
              <ngx-skeleton-loader [theme]="{width: '300px','border-radius': '16px',height: '40px'}">
              </ngx-skeleton-loader>
            </div>

            <div class="w-100">
              <ngx-skeleton-loader [theme]="{width: '100%','border-radius': '16px',height: '300px'}">
              </ngx-skeleton-loader>
            </div>
          </ng-container>
          <div class="d-flex justify-content-between mt-1 mx-0 align-items-center mb-5" *ngIf="paginationLoading">
            <ngx-skeleton-loader [theme]="{width: '150px','border-radius': '16px',height: '30px'}">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{width: '150px','border-radius': '16px',height: '30px'}">
            </ngx-skeleton-loader>
          </div>
        </div>


      </div>

      <div class="col-md-12 col-lg-3 px-0 border-left order-2">
        <div class="m-2">
          <h3 class="c-gray mb-0 font-weight-bolder">คอร์สออนไลน์ที่เกี่ยวข้อง</h3>
        </div>
        <ng-container *ngIf="courseList?.data.length != 0 && !courseLoading">
          <div class="course-list-group row mx-0">
            <a [routerLink]="['/watch',course._id]"
              class="course-list-group-item list-group-item-action item-rounded col-12"
              *ngFor="let course of courseList?.data;let i as index">
              <div class="row mx-0">
                <div class="col px-0">
                  <div class="mr-1">
                    <img src="assets/images/momo-class/c1.png" alt="" class="w-100 rounded">
                  </div>
                </div>
                <div class="col-6 col-sm-8 col-lg-6 col-xxl-8 px-0">
                  <h5 class="c-gray mb-0 font-weight-bolder text-ellipsis-2">{{ course.name }}</h5>
                  <div class="d-flex w-100 justify-content-between">
                    <p class="text-muted mb-0 text-ellipsis-1">
                      <i class="fa-regular fa-clock"></i>
                      {{ course.minutes ?? 0 }} นาที
                    </p>
                    <div>
                      <span class="badge bg-light-primary">{{course.total_video }} บท</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="!courseLoading && courseList?.data.length == 0">
          <div class="no-data">
            <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
            <div class="no-data-content">
              <h1 class="font-weight-bold mb-0">
                ยังไม่มีคอร์สเรียน
              </h1>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="courseLoading">
          <div class="course-list-group row mx-0">
            <a href="javascript:void(0)" class="course-list-group-item list-group-item-action item-rounded col-12"
              *ngFor="let item of [0,1,2,3,4];let i as index">
              <div class="row mx-0">
                <div class="col px-0">
                  <div class="mr-1">
                    <ngx-skeleton-loader
                      [theme]="{width: '100%','border-radius': '16px',height: '4.375rem','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                  </div>
                </div>
                <div class="col-6 col-sm-8 px-0">
                  <div class="mb-0">
                    <ngx-skeleton-loader
                      [theme]="{width: '100%','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      [theme]="{width: '160px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                  </div>
                  <div class="d-flex w-100 justify-content-between">
                    <p class="mb-0">
                      <ngx-skeleton-loader
                        [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </p>
                    <ngx-skeleton-loader
                      [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                    </ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="categoryList?.data && !categoryLoading">
          <div class="course-list-group row mx-0">
            <a href="javascript:void(0)" class="course-list-group-item list-group-item-action col-12 col-md-6 col-lg-12"
              *ngFor="let cate of categoryList?.data;let i as index">
              <div class="row mx-0">
                <div class="col-12 col-sm-6 col-lg-12 col-xl-6 px-0">
                  <div class="mr-0 mr-sm-1 mr-lg-0 mr-xl-1">
                    <img src="assets/images/momo-class/c1.png" alt="" class="w-100 rounded">
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-12 col-xl-6 px-0">
                  <h5 class="c-gray mb-0 font-weight-bolder text-ellipsis-2">{{ cate.name }}</h5>
                  <div class="d-flex w-100 justify-content-between">
                    <p class="text-muted mb-0 text-ellipsis-1">
                      <i class="fa-regular fa-clock"></i>
                      1-2 ชั่วโมง
                    </p>
                    <div>
                      <span class="badge bg-light-primary">6 นาที</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="categoryList?.length == 0 && !categoryLoading">
          <div class="no-data mt-md-5 py-5">
            <div class="no-data-content">
              <i class="fa-sharp fa-solid fa-film-slash fa-2x"></i>
              <h3>ยังไม่มีคอร์ส</h3>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="categoryLoading && !categoryList?.data">
          <div class="menu-list-group">
            <div class="menu-list-group-item list-group-item-action" *ngFor="let item of [0,1,2,3,4];let i as index">
              <ngx-skeleton-loader
                [theme]="{width: '200px','border-radius': '16px',height: '100px','margin-bottom': '0px'}">
              </ngx-skeleton-loader>
              <div class="course-content">
                <ngx-skeleton-loader
                  [theme]="{width: '160px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                </ngx-skeleton-loader>
                <div class="course-content-footer">
                  <ngx-skeleton-loader
                    [theme]="{width: '80px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </ng-container> -->

      </div>
    </div>
    <div class="fixed-menu d-lg-none">
      <a class="fixed-menu-item" routerLink="/translation" *ngIf="currentUser.datauser.momoclass == 'advance'">
        <i class="fa-regular fa-message-lines mr-50"></i>
        <h5 class="mb-0">สอบถามล่ามจีนโดยตรง</h5>
      </a>
      <a class="fixed-menu-item" href="https://customer.momocargo.com/home">
        <i class="fa-regular fa-arrow-right-from-bracket mr-50 text-primary"></i>
        <h5 class="text-primary mb-0">ออกจากห้องเรียน</h5>
      </a>
    </div>
  </div>