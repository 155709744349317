<div class="content-wrapper p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <div class="row m-2 justify-content-center">
      <div class="col-xl-10 px-0">
        <div class="card no-box-shadow card-border-top mb-0">
          <div class="m-2">

            <!-- <h1 class="c-gray mb-1">คอร์สแนะนำ</h1>
            <ng-container *ngIf="suggestionList?.data.length != 0 && !suggestionLoading">
              <div class="course-list-group row">
                <a [routerLink]="['/watch',course._id]"
                  class="course-list-group-item list-group-item-action item-rounded col-6 col-md-4 col-xl-2"
                  *ngFor="let course of suggestionList?.data;let i as index">
                  <div class="row mx-0">
                    <div class="col-12 px-0">
                      <img [src]="course.image" alt="" class="w-100 rounded">
                    </div>
                    <div class="col-12 px-0">
                      <h5 class="c-gray mb-0 font-weight-bolder text-ellipsis-2">{{ course.name }}</h5>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="text-muted mb-0 text-ellipsis-1">
                          <i class="fa-regular fa-clock"></i>
                          {{ course.minutes }} นาที
                        </p>
                        <div>
                          <span class="badge bg-light-primary">{{course.total_video }} บท</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="!suggestionLoading && suggestionList?.data.length == 0">
              <div class="no-data">
                <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
                <div class="no-data-content">
                  <h1 class="font-weight-bold mb-0">
                    ยังไม่มีคอร์สเรียน
                  </h1>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="suggestionLoading">
              <div class="course-list-group row">
                <a href="javascript:void(0)"
                  class="course-list-group-item list-group-item-action item-rounded col-6 col-md-4 col-xl-2"
                  *ngFor="let item of [0,1,2,3,4];let i as index">
                  <div class="row mx-0">
                    <div class="col-12 px-0">
                      <ngx-skeleton-loader
                        [theme]="{width: '100%','border-radius': '16px',height: '4.375rem','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="col-12 px-0">
                      <div class="mb-0">
                        <ngx-skeleton-loader
                          [theme]="{width: '100%','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                          [theme]="{width: '160px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-0">
                          <ngx-skeleton-loader
                            [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                          </ngx-skeleton-loader>
                        </p>
                        <ngx-skeleton-loader
                          [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container> -->

            <h1 class="c-gray mb-1 font-weight-bolder mt-2">คอร์สเรียนทั้งหมด</h1>
            <ng-container *ngIf="courseList?.data.length != 0 && !courseLoading">
              <div class="course-list-group row">
                <a [routerLink]="['/watch',course._id]"
                  class="course-list-group-item list-group-item-action item-rounded col-6 col-md-4 col-xl-2"
                  *ngFor="let course of courseList?.data;let i as index">
                  <div class="row mx-0">
                    <div class="col-12 px-0">
                      <img [src]="course.image" alt="" class="w-100 rounded">
                    </div>
                    <div class="col-12 px-0">
                      <h5 class="c-gray mb-0 font-weight-bolder text-ellipsis-2">{{ course.name }}</h5>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="text-muted mb-0 text-ellipsis-1">
                          <i class="fa-regular fa-clock"></i>
                          {{ course.minutes }} นาที
                        </p>
                        <div>
                          <span class="badge bg-light-primary">{{course.total_video }} บท</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="!courseLoading && courseList?.data.length == 0">
              <div class="no-data">
                <i class="fa-solid fa-graduation-cap fa-3x  text-muted"></i>
                <div class="no-data-content">
                  <h1 class="font-weight-bold mb-0">
                    ยังไม่มีคอร์สเรียน
                  </h1>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="courseLoading">
              <div class="course-list-group row">
                <a href="javascript:void(0)"
                  class="course-list-group-item list-group-item-action item-rounded col-6 col-md-4 col-xl-2"
                  *ngFor="let item of [0,1,2,3,4];let i as index">
                  <div class="row mx-0">
                    <div class="col-12 px-0">
                      <ngx-skeleton-loader
                        [theme]="{width: '100%','border-radius': '16px',height: '4.375rem','margin-bottom': '0px'}">
                      </ngx-skeleton-loader>
                    </div>
                    <div class="col-12 px-0">
                      <div class="mb-0">
                        <ngx-skeleton-loader
                          [theme]="{width: '100%','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                          [theme]="{width: '160px','border-radius': '4px',height: '20px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-0">
                          <ngx-skeleton-loader
                            [theme]="{width: '60px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                          </ngx-skeleton-loader>
                        </p>
                        <ngx-skeleton-loader
                          [theme]="{width: '40px','border-radius': '4px',height: '16px','margin-bottom': '0px'}">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
          </div>

          <div class="row mt-2">
            <div class="col-12 d-flex flex-wrap justify-content-center align-items-center">
              <div class="custom-pagination">
                <!-- Pagination -->
                <ngb-pagination #numPages [boundaryLinks]="true" [maxSize]="5" [ellipses]="false"
                  [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                  (pageChange)="getCourseList()">
                  <ng-template ngbPaginationFirst><i class="fa-regular fa-chevron-double-left"></i></ng-template>
                  <ng-template ngbPaginationLast><i class="fa-regular fa-chevron-double-right"></i></ng-template>
                  <ng-template ngbPaginationPrevious><i class="fa-regular fa-chevron-left"></i></ng-template>
                  <ng-template ngbPaginationNext><i class="fa-regular fa-chevron-right"></i></ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>