import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { map, takeUntil } from 'rxjs/operators';
import { CourseService } from './../course.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Video } from '../course.model';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CourseDetailComponent implements OnInit, OnDestroy {
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: Event) {
  //   alert('Close Tap 2');
  //   event.preventDefault();
  //   event.stopPropagation();
  //   console.log('Close Tap 2', event);
  // }

  listLoading = false;
  videoLoading = false;
  courseLoading = false;
  paginationLoading = false;
  courseList;
  videoList: Video[];
  currentVideo: Video;
  courseProgress = '0';
  courseComplete = 0;
  videoLink = '';

  nextVideo = '';
  prevVideo = '';

  currentCategory: any;
  courseId: string = '';
  videoId: string;
  videoIndex: number;
  videoStatusSub: Subscription;

  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  public currentUser = JSON.parse(localStorage.getItem('_mus'));

  private _unsubscribeAll = new Subject<any>();

  constructor(private _courseService: CourseService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((paramMap: ParamMap) => {
        console.log('old video', this.videoId);
        console.log('new video', paramMap.get('videoId'));
        if (paramMap.get('courseId') != this.courseId) {
          this.courseId = paramMap.get('courseId');
          this.listLoading = true;
          this.videoLoading = true;
          this.paginationLoading = true;
          this.courseLoading = true;
          this._courseService.getCategory(this.courseId).pipe(takeUntil(this._unsubscribeAll)).subscribe(course => {
            this.currentCategory = course.data;
            console.log('getCategory', course.data);
          });

          this._courseService.getVideoList(1, 30, this.courseId);
          this._courseService.getCurrentVideoList().pipe(takeUntil(this._unsubscribeAll)).subscribe(courseData => {
            console.log('getCurrentVideoList', courseData.data);
            this.listLoading = false;
            this.videoList = courseData.data;
            this.calculateProgress();
            this.getCategoryList();
          });
        }
        if (paramMap.has('courseId') && (paramMap.get('videoId') != this.videoId)) {
          console.log('setVideo');
          this.setVideo(paramMap.get('videoId'));
        } else {
          if (this.videoList.length > 0) {
            setTimeout(() => {
              this.autoPlayback();
            }, 3000);
          }
        }
      });
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  autoPlayback() {
    const list = this.videoList;
    if (list.length > 0) {
      const onPause = list.findIndex(p => p.status === 1);
      const notStart = list.findIndex(p => p.status === 0);
      if (onPause >= 0) {
        this.router.navigate(['/course/watch/', this.courseId, list[onPause]._id]);
      } else if (onPause < 0 && notStart >= 0) {
        this.router.navigate(['/course/watch/', this.courseId, list[notStart]._id]);
      } else {
        this.router.navigate(['/course/watch/', this.courseId, list[0]._id]);
      }
    } else {
      this.videoLoading = false;
      this.paginationLoading = false;
    }
  }

  setVideo(videoId: string) {
    this.videoId = videoId;
    this.nextVideo = '';
    this.prevVideo = '';
    this.videoLoading = true;
    this.paginationLoading = true;
    this._courseService.getVideo(this.videoId);
    this._courseService.getCurrentVideo().pipe(takeUntil(this._unsubscribeAll)).subscribe(videoData => {
      this.videoLoading = false;
      this.currentVideo = videoData;
      this.videoLink = videoData.video_link;
      setTimeout(() => {
        this.setPaginationLink();
      }, 2000);
    });

    this.videoStatusSub = this._courseService.getVideoStatusListener().pipe(takeUntil(this._unsubscribeAll))
      .subscribe((videoStatus: any) => {
        if ((this.videoList[this.videoIndex].status !== videoStatus.status) && (this.videoList[this.videoIndex].status <= videoStatus.status)) {
          this.videoList[this.videoIndex].status = videoStatus.status;
          this.updateSeenVideo(this.currentVideo.video_id);
          this.calculateProgress();
        }
        if (videoStatus == 2) {
          const nextVideoElement = document.getElementById('nextVideo');
          setTimeout(() => {
            nextVideoElement.click();
          }, 1000);
        }
      });
  }

  setPaginationLink() {
    const list = this.videoList;
    this.videoIndex = list.findIndex(p => p._id === this.currentVideo._id);
    // console.log('videoIndex', this.videoIndex, list.length);
    if (this.videoIndex == 0 && list.length == 1) {
      console.log(1);
      this.prevVideo = '';
      this.nextVideo = '';
    } else if (this.videoIndex == 0 && list.length >= 2) {
      console.log(2);
      this.prevVideo = '';
      this.nextVideo = list[this.videoIndex + 1]._id;
    } else if (this.videoIndex >= 1 && list.length >= 3 && (this.videoIndex + 1) != list.length) {
      console.log(3);
      this.prevVideo = list[this.videoIndex - 1]._id;
      this.nextVideo = list[this.videoIndex + 1]._id;
    } else if (this.videoIndex >= 0 && list.length >= 1 && (this.videoIndex + 1) == list.length) {
      console.log(4);
      this.prevVideo = list[this.videoIndex - 1]._id;
      this.nextVideo = '';
    }

    this.paginationLoading = false;
  }

  updateSeenVideo(videoId: number) {
    // const currentUser = JSON.parse(localStorage.getItem('_mus'));
    this._courseService.updateSeenVideo(videoId).pipe(takeUntil(this._unsubscribeAll)).subscribe(seenVideoData => {
      console.log('seenVideoData', seenVideoData);
    });
  }

  calculateProgress() {
    let total = this.videoList.length;
    // let complete = 0;
    this.courseComplete = 0;
    let inComplete = 0;

    this.videoList.forEach(v => {
      if (v.status == 2) {
        this.courseComplete += 1;
      }
      if (v.status == 1) {
        inComplete += 1;
      }
    });

    this.courseProgress = ((this.courseComplete / total) * 100).toFixed(0);
    if (!this.courseProgress || this.courseProgress == 'NaN') {
      this.courseProgress = '0';
    }

    // const localPlaybackTime = JSON.parse(localStorage.getItem('momoclass-playback'));
    // const listPlaybackTime = localPlaybackTime.metaData.filter(p => {
    //   p.course._id === this.courseId
    // });
    // console.log('listPlaybackTime', listPlaybackTime);
  }

  getCategoryList() {
    this.courseLoading = true;
    this._courseService.getCategoryList(1, 5, 'all').pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        // this._toastrService.error(response.message);
      } else {
        this.courseList = response.data;
      }
      this.courseLoading = false;
    }, (error) => {
      console.error(error);
      // this._toastrService.error(error);
      this.courseLoading = false;
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
