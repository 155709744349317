import { VersionControlService } from '@core/services/version-control.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-version-control',
  templateUrl: './version-control.component.html',
  styleUrls: ['./version-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VersionControlComponent implements OnInit {
  public contentHeader: object;
  public changeList: any;

  constructor(public versionControlService: VersionControlService) { }

  ngOnInit() {
    console.log('change log');
    this.contentHeader = {
      headerTitle: 'จัดการไอดีสั่งสินค้า',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'แดชบอร์ด',
            isLink: true,
            link: '/'
          },
          {
            name: 'ประวัติเวอร์ชั่น',
            isLink: false
          },
        ]
      }
    };

    this.changeList = this.versionControlService.getChangeList();
  }


}
