import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private dashboardData = new Subject<any>();

  constructor(private _httpClient: HttpClient) {
  }

  getDashboard(): Observable<any> {
    return this._httpClient.get<any>(`/func/user/dashboard/momoclass`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  getTranslate(page: number, limit: number): Observable<any> {
    return this._httpClient.get<any>(`/translator/user/get/question/${page}/${limit}`).pipe(
      // return this._httpClient.get<any>(`/translator/get/all`).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    return throwError('Something went wrong, please try again later.');
  }

  httpOptions(body: any) {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + localStorage.getItem('token')),
      body: body
    }
  }
}
