<!-- app-content-header start -->
<!-- <ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-12 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
        <div class="form-group breadcrum-right">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect
            >
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/']"
                ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Todo</a
              >
              <a ngbDropdownItem [routerLink]="['/']"
                ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chat</a
              >
              <a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'mail'" [class]="'mr-50'"></span> Email</a>
              <a ngbDropdownItem [routerLink]="['/']"
                ><span [data-feather]="'calendar'" [class]="'mr-50'"></span> Calendar</a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container> -->
<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row align-items-center ml-0 momo-container">
    <div class="content-header-left col-lg-7 col-xxl-8 col-12 pl-0 d-none d-lg-flex">
      <div class="row breadcrumbs-top ml-2">
        <div class="col-12 d-flex pl-25">
          <!-- <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2> -->
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <!-- <ng-container *ngIf="contentHeader.actionButton"> -->
    <div class="content-header-right col-lg-5 col-xxl-4 col-12 ">
      <div class="breadcrum-right">
        <div class="d-flex align-items-center justify-content-end">
          <div class=" c-gray0">
            <!-- <a class="d-flex align-items-center cursor-pointer" [routerLink]="'/exit-learning'"> -->
            <a class="d-flex align-items-center cursor-pointer" href="https://customer.momocargo.com/dashboard">
              <div class="position-relative d-inline-block">
                <span class="fa-solid fa-arrows-retweet fs-6 c-gray3"></span>
              </div>
              <span class="fs-4 c-gray3 ml-75">สลับไปยังฝากสั่งสินค้า</span>
            </a>
          </div>
        </div>

        <!-- <app-navbar-notification></app-navbar-notification> -->
      </div>
    </div>
    <!-- </ng-container> -->
  </div>
  <!-- app-toast-container component -->
  <!-- <app-toast-container [ngStyle]="toastStyle" aria-live="polite" aria-atomic="true"></app-toast-container> -->
</ng-container>
<!-- app-content-header end -->
<!-- app-content-header end -->