import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CourseService } from './../course/course.service';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from './dashboard.service';
import { Subject } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ViewContainerRef, TemplateRef, Input, ElementRef, ChangeDetectorRef, AfterContentChecked, OnDestroy } from '@angular/core';

import { locale as en } from './i18n/en'

import { CoreTranslationService } from '@core/services/translation.service'

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexStroke,
  ApexFill,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";
import moment from 'moment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  // Decorator
  @ViewChild('learningHours') learningHours: any;
  public learningHoursOptions: Partial<ChartOptions>;

  public contentHeader: object
  public currentUser: any;

  public dashboardLoading = false;
  public dashboardData: any;

  public translateLoading = false;
  public translateData: any;

  public courseLoading = false;
  public courseData: any;

  // config = {};
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _dashboardService: DashboardService,
    private _courseService: CourseService,
    private _toastrService: ToastrService,
    private _stripHtmlPipe: StripHtmlPipe
  ) {
    this._unsubscribeAll = new Subject();
    this.learningHoursOptions = {
      series: [
        {
          name: "เวลาที่เรียนไป",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chart: {
        height: 200,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: [
        "#F6EC92",
        "#ECABED",
        "#ABE8B1",
        "#F9B994",
        "#B7D5F0",
        "#A09AF4",
        "#F49A9A"
      ],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },
      xaxis: {
        categories: [
          "จันทร์",
          "อังคาร",
          "พุธ",
          "พฤหัสบดี",
          "ศุกร์",
          "เสาร์",
          "อาทิตย์"
        ],
        labels: {
          style: {
            fontSize: "12px"
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (y) {
            return y + " นาที.";
          }
        }
      },
    };

  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('_mus'));
    if (currentUser) {
      this.currentUser = currentUser.datauser;
      console.log(this.currentUser);
    }

    this.contentHeader = {
      headerTitle: 'หน้าแรก',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'หน้าแรก',
            isLink: false,
            link: '/'
          },

        ]
      }
    }

    this.dashboardLoading = true;
    this.translateLoading = true;
    this.courseLoading = true;

    this.getDashboard();
  }

  /**
   * After View Init
   */
  ngAfterViewInit() {
    this.getTranslate();
  }

  /**
  * On destroy
  */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getDashboard() {
    this.dashboardLoading = true;
    this._dashboardService.getDashboard().pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.dashboardData = response.data;
        this.learningHoursOptions.series = [{
          data: this.dashboardData?.attend?.value
        }];
        console.log(this.dashboardData);
      }
      this.dashboardLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.dashboardLoading = false;
    })
  }

  getTranslate() {
    this.translateLoading = true;
    this._dashboardService.getTranslate(1, 10).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.translateData = response;
        console.log(this.translateData);
        // this.getCourseList();
      }
      this.translateLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.translateLoading = false;
    })
  }

  getCourseList() {
    this.courseLoading = true;
    this._courseService.getCategoryList(1, 5, 'all').pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        // this.courseData = response.data;
        console.log(this.courseData);
      }
      this.courseLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      this.courseLoading = false;
    })
  }

  getPackageName() {
    switch (this.currentUser?.momoclass) {
      case 'none':
        return 'ยังไม่ได้สมัครแพ็คเกจ'
        break;
      case 'basic':
        return 'สมัครแพ็คเกจธรรมดา'
        break;
      case 'advance':
        return 'สมัครแพ็คเกจพรีเมี่ยม'
        break;

      default:
        break;
    }
  }

  onScrollEvent(event: any): void {
    console.log(event);
  }

  copyCode(inputTextValue) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = this._stripHtmlPipe.transform(inputTextValue);
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    // console.log(this._stripHtmlPipe.transform(inputTextValue));
    this._toastrService.success('คัดลอกไปยังคลิปบอร์ดแล้ว!!');
  }

  panelExpanded(event, panel) {
    const thisPanelId = event;
    const thisPanelElem = document.getElementById(thisPanelId);

    if (panel.isExpanded(thisPanelId)) {
      thisPanelElem.parentElement.classList.add("active");
    }
  }

  panelCollapse(event, panel) {
    const thisPanelId = event;
    const thisPanelElem = document.getElementById(thisPanelId);

    if (!panel.isExpanded(thisPanelId)) {
      thisPanelElem.parentElement.classList.remove("active");
    }
  }
}
