import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { CourseService } from './../course/course.service';
import { TranslationService } from './translation.service';
import { ToastrService } from 'ngx-toastr';
import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Translation } from './translation';
import { mimeType } from '@core/validator/mime-type.validator';
import { FileUploader } from 'ng2-file-upload';
import { S3Service } from '@core/services/s3.service';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit, OnDestroy {
  public listPage: number = 1;
  public listPageSize: number = 10;
  public listCollectionSize: number = 0;
  public listTranslateLoading: boolean = false;
  public listTranslateData: Translation;
  public search: string = '';

  public page: number = 1;
  public pageSize: number = 10;
  public collectionSize: number = 0;
  public translateLoading: boolean = false;
  public translateData: Translation;

  public courseLoading: boolean = true;
  public courseList: any;

  public translationForm: FormGroup;
  public imagePreview: string;
  public currentFile: File;
  public currentImagePath: string;
  public formSubmitLoading: boolean = false;

  public contentHeader: object

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean = false;
  hasAnotherDropZoneOver: boolean = false;
  response: string = '';

  URL = 'https://test.com/api/';

  private _unsubscribeAll = new Subject();

  constructor(
    private _translationService: TranslationService,
    private _toastrService: ToastrService,
    private _courseService: CourseService,
    private _stripHtmlPipe: StripHtmlPipe,
    private _s3Service: S3Service
  ) {
    this.uploader = new FileUploader({
      url: this.URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }

  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'แปลภาษาไทย - จีน',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'หน้าแรก',
            isLink: true,
            link: '/'
          },
          {
            name: 'แปลภาษาไทย - จีน',
            isLink: false
          }
        ]
      }
    }
    this.getTranslateList();
    this.getMyTranslate();
    this.getCourseList();

    this.translationForm = new FormGroup({
      title: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(1)]
      }),
      imagePath: new FormControl(null)
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get title() { return this.translationForm.get('title'); }

  getTranslateList() {
    this.listTranslateLoading = true;
    this._translationService.getTranslateList(this.listPage, this.listPageSize, 'all').pipe(takeUntil(this._unsubscribeAll)).subscribe((response: Translation) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.listTranslateData = response;
        this.listCollectionSize = response.data.total_data;
        console.log(this.translateData);
      }
      this.listTranslateLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.listTranslateLoading = false;
      this.listTranslateData = undefined;
    })
  }

  getMyTranslate() {
    this.translateLoading = true;
    this._translationService.getTranslate(this.page, this.pageSize).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.translateData = response;
        this.collectionSize = response.data.total_data;
        console.log(this.translateData);
      }
      this.translateLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.translateLoading = false;
      this.translateData = undefined;
    })
  }

  panelExpanded(event, panel) {
    const thisPanelId = event;
    const thisPanelElem = document.getElementById(thisPanelId);

    if (panel.isExpanded(thisPanelId)) {
      thisPanelElem.parentElement.classList.add("active");
    }
  }

  panelCollapse(event, panel) {
    const thisPanelId = event;
    const thisPanelElem = document.getElementById(thisPanelId);

    if (!panel.isExpanded(thisPanelId)) {
      thisPanelElem.parentElement.classList.remove("active");
    }
  }

  getCourseList() {
    this.courseLoading = true;
    this._courseService.getCategoryList(this.page, this.pageSize, 'all').pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this.courseList = response.data;
        this.collectionSize = response.data.total_data;
      }
      this.courseLoading = false;
    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.courseLoading = false;
    })
  }

  copyCode(inputTextValue) {
    const selectBox = document.createElement('textarea');
    selectBox.style.position = 'fixed';
    selectBox.value = this._stripHtmlPipe.transform(inputTextValue);
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    this._toastrService.success('คัดลอกไปยังคลิปบอร์ดแล้ว!!');
  }

  onImagePicked(type: string, event: Event) {
    console.log(type, event);
    if (type == 'drop') {
      this.currentFile = event[0];
    } else {
      this.currentFile = (event.target as HTMLInputElement).files[0];
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(this.currentFile);

    if (this.currentFile) {
      this._s3Service.uploadFile(this.currentFile, 'class-translator').then(res => {
        if (res) {
          this.currentImagePath = res;
          console.log('currentImagePath', this.currentImagePath);
          this.translationForm.patchValue({
            imagePath: this.currentImagePath
          });
          this._toastrService.success('อัพโหลดสำเร็จแล้ว');
        } else {
          this._toastrService.error('อัพโหลดไม่สำเร็จ ลองใหม่อีกครั้ง');
        }
      });
    }
  }

  onSaveTranslator() {
    this.formSubmitLoading = true;

    if (this.translationForm.invalid) {
      this.formSubmitLoading = false;
      return;
    }
    let body = {
      question: this.translationForm.value.title,
      image: this.translationForm.value.imagePath ?? ''
    }
    this._translationService.postTranslation(body).pipe(takeUntil(this._unsubscribeAll)).subscribe((response: { status: boolean, auth: boolean, message: string, data: any }) => {
      if (response.status === false) {
        console.error(response.message);
        this._toastrService.error(response.message);
      } else {
        this._toastrService.error(response.message);
      }
      this.formSubmitLoading = false;
      this.translationForm.reset();
      this.currentFile = null;
      this.currentImagePath = '';
      this.formSubmitLoading = false;
      this.imagePreview = '';

    }, (error) => {
      console.error(error);
      this._toastrService.error(error);
      // this.dashboardLoading = false;
    });

  }

}
