import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<any>;

  //private
  private currentUserSubject: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _cookieService: CookieService,
    private _router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('_mus')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('_mus', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                user.role +
                ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  updateCurrentUser(user) {
    let local = JSON.parse(localStorage.getItem('_mus'));
    const cookieExists: boolean = this._cookieService.check('_mtk');
    if (cookieExists) {
      let cookie = JSON.parse(this._cookieService.get('_mtk'));
      console.log(local, cookie, cookie.token);

      if (!local || local.token !== cookie.token) {
        let currentUser = { token: cookie.token, user_id: cookie.id };
        this.currentUserSubject.next(currentUser);
        localStorage.setItem('_mus', JSON.stringify(currentUser));
        window.location.reload();
      } else if (user) {
        local.datauser = { ...local.datauser, ...user };
        this.currentUserSubject.next(local);
        localStorage.setItem('_mus', JSON.stringify(local));
      }
    } else {
      localStorage.removeItem('_mus');
      this._router.navigate(['/error/not-authorized']);
      // window.location.href = 'https://s1.momocargo.co/';
    }
    // let cookie = { "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiVXNlciIsInVzZXJfaWQiOjM4MzA3LCJfaWQiOiI2NTY2YWJhZGMwZmUzYmU3OWRhNWM5ZjEiLCJsYXN0X29ubGluZSI6IiIsImlhdCI6MTcwMTIyNzQzN30.JcRWh7DN56K6xVsSZ2eLBuJoBMSEuFz2o9MApaGrIBU", "datauser": { "_id": "6566abadc0fe3be79da5c9f1", "user_id": 38307, "user_code": "000003", "code_int": 3, "phone": "0835091421", "birthday": null, "avatar": "", "company": null, "price_group": { "_id": "64ba30b12555dea5a57bab27", "gid": 1, "title": "ลูกค้าระดับที่ 1 - Silver", "code": "S", "cumulative": 174999.99, "color": "#C0C0C0", "display_title": "Silver", "icon": "https://momocargo.s3.ap-southeast-1.amazonaws.com/avatar/user/1686732821476.png" }, "upgrade_rank": false, "momoclass": "none", "last_online": "", "password_reset_token": "", "password_reset_datetime": null, "email_verified_at": null, "mirror_username": null, "mirror_password": null, "line_id": "", "created_date": "2023-11-29 10:10:37", "updated_date": "", "token": [], "__v": 0 }, "is_register": true, "user_id": "6566abadc0fe3be79da5c9f1" };
  }

  checkToken() {
    let local = JSON.parse(localStorage.getItem('_mus'));
    const cookieExists: boolean = this._cookieService.check('_mtk');
    if (cookieExists) {
      let cookie = JSON.parse(this._cookieService.get('_mtk'));
      if (!local || local.token !== cookie.token) {
        let currentUser = { token: cookie.token, user_id: cookie.id };
        this.currentUserSubject.next(currentUser);
        localStorage.setItem('_mus', JSON.stringify(currentUser));
      }
    } else {
      localStorage.removeItem('_mus');
      this._router.navigate(['/error/not-authorized']);
      // window.location.href = 'https://s1.momocargo.co/';
    }
  }

  /**
   * Get user by id
   */
  getLiveProfile(): Observable<any> {
    this.checkToken();
    let currentUser = JSON.parse(localStorage.getItem('_mus'));
    if (currentUser) {
      return this._http.get<any>(`/user/user/info`).pipe(
        map(user => {
          if (user.created_date) {
            this.updateCurrentUser(user);
            console.log('alive');
          } else if (!user.auth) {
            console.log('die');
            this.logout();
          }
        }),
        catchError(this.handleError)
      );
    } else {
      this.updateCurrentUser(null);
      // return throwError('Something went wrong, please try again later.');
    }
  }

  /**
   * User logout
   *
   */
  logout() {
    this._http.get<any>(`/auth/logout`).subscribe(res => {
      // remove user from local storage to log user out
      localStorage.removeItem('_mus');
      this._cookieService.delete('_mus');
      this.currentUserSubject.next(null);
      // notify
      window.location.href = 'https://customer.momocargo.co/home';
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    return throwError('Something went wrong, please try again later.');
  }
}
